<template>
  <v-simple-table class="calendar-container">
    <thead>
      <template v-for="(day, rowIndex) in availableDays">
        <tr :key="`dateColumn-${rowIndex + 1}`" class="date-row">
          <td
            v-for="(day, dayIndex) in day"
            :key="`date-${dayIndex}`"
            class="day-column px-0"
            :date="day.fullDate"
          >
            <v-col cols="12" class="text-center px-0">
              <div v-if="rowIndex === 0" class="day-names">
                {{ daysInAWeek[dayIndex] }}
              </div>
              <div class="day-date" :class="{'today': isToday(day)}">
                {{ getDayLabel(day) }}
              </div>
            </v-col>
          </td>
        </tr>

        <tr v-if="rowIndex === 0 && loading" :key="`loading-bar-${rowIndex}`">
          <td colspan="7" class="px-0" style="height: 100%">
            <v-progress-linear indeterminate></v-progress-linear>
          </td>
        </tr>

        <tr
          v-for="(item, timeIndex) in 2"
          :key="`schedule-row-${rowIndex}-${timeIndex}`"
          :class="`${timeIndex ? 'night' : 'day'}-schedule-row`"
        >
          <td
            v-for="(singleDay, dayIndex) in day"
            :key="`event-${timeIndex ? 'night' : 'day'}-${dayIndex}`"
            style="vertical-align: top"
            class="day-column px-0 text-start"
          >
            <div class="day-item">
              <v-col v-if="!hideEmployeeRow" class="unassigned-employee-list">
                <v-row align="center">
                  <v-col cols="2"> 空 </v-col>

                  <v-col cols="10">
                    <v-row>
                      <v-col
                        v-for="(employee,
                        employeeIndex) in getAvailableEmployeesInADate(
                          singleDay.fullDate,
                          timeIndex
                        )"
                        cols="auto"
                        class="px-0 py-1"
                        :key="`employee-list-${employeeIndex}`"
                      >
                        <div class="unassigned-employee-list-item">
                          {{ employee.name }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <MonthlyEvent
                v-for="(event, i) in events[singleDay.fullDate]?.[timeIndex]"
                :key="`event-${timeIndex ? 'night' : 'day'}-item-${i}`"
                :event="event"
                @event:click="$emit('event:click', $event)"
              ></MonthlyEvent>
            </div>
          </td>
        </tr>
      </template>
    </thead>
  </v-simple-table>
</template>

<script>
import MonthlyEvent from '@/components/admin/partials/Calendar/Event/Event'
import dayjs from 'dayjs'
import { flattenDeep, groupBy, xorBy } from 'lodash'

export default {
  name: 'MonthlyCalendar',
  components: { MonthlyEvent },
  props: {
    month: {
      type: Object,
      default: () => {},
      required: false
    },
    hideEmployeeRow: {
      type: Boolean,
      default: false,
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    unassignedEmployees: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  created() {
    this.init()
  },
  watch: {
    month() {
      this.init()
    }
  },
  computed: {
    currentMonth() {
      return this.month
    },

    allTasks() {
      return this.$store.getters.allTasks
    },

    allUsers() {
      return this.$store.getters.allUsers
    },

    availableWorkers() {
      return this.$store.getters.availableUsers
    },

    availableDays() {
      return this.dayChunks(this.days, this.daysInAWeek.length)
    }
  },
  data: () => ({
    daysInAWeek: ['月', '火', '水', '木', '金', '土', '日'],
    days: [],
    events: []
  }),
  methods: {
    dayChunks(array, chunk_size) {
      return Array(Math.ceil(array.length / chunk_size))
        .fill()
        .map((_, index) => index * chunk_size)
        .map(begin => array.slice(begin, begin + chunk_size))
    },

    async init() {
      let days = []

      let beginDate = this.currentMonth.startOf('month').startOf('week')
      let endDate = this.currentMonth.endOf('month').endOf('week')
      let params = {
        date_from: beginDate.format('YYYY-MM-DD'),
        date_to: endDate.format('YYYY-MM-DD')
      }
      await this.$store.dispatch('USER_GET_ALL_AVAILABLE_WORKER', params)

      while (beginDate.isSameOrBefore(endDate, 'day')) {
        let fullDate = beginDate.format('YYYY-MM-DD')
        days.push({
          date: beginDate.format('D'),
          month: beginDate.format('M'),
          monthName: beginDate.format('MMMM'),
          fullDate: fullDate,
          type: beginDate.isBefore(this.currentMonth, 'month')
            ? 'trailing'
            : beginDate.isAfter(this.currentMonth, 'month')
            ? 'leading'
            : 'current',
          first: beginDate.date() === 1
        })

        beginDate = beginDate.add(1, 'day')
      }
      this.days = days
      this.initEvents()
    },

    initEvents() {
      let events = groupBy(this.allTasks, 'date')
      for (const date in events) {
        events[date] = events[date].sort((a, b) => {
          let dateA = dayjs(`${a.date} ${a.task_start_time}`)
          let dateB = dayjs(`${b.date} ${b.task_start_time}`)
          return dateA.isBefore(dateB) ? -1 : 1
        })
        events[date] = groupBy(events[date], 'time')
        for (const [key, value] of Object.entries(events[date])) {
          let users = this.availableWorkers.map(user => {
            return {
              id: user.id,
              name: user.full_name.charAt(0),
              offDays: user.off_days.filter(offday => offday.date === date)
            }
          })
          let taskMembers = flattenDeep(
            value.map(i => {
              return flattenDeep(i.task_member)
            })
          )
          events[date][key].available_user = xorBy(taskMembers, users, 'id')
        }
      }
      this.events = events
    },

    getDayLabel(day) {
      return day.first ? `${day.month}/${day.date}` : day.date
    },

    getAvailableEmployeesInADate(date, timeIndex) {
      return (
        this.events[date]?.[timeIndex]?.available_user ||
        this.availableWorkers.map(user => {
          return {
            id: user.id,
            name: user.full_name.charAt(0),
            offDays: user.off_days.filter(offday => offday.date === date)
          }
        })
      )
    },

    isToday(date) {
      return date.fullDate === dayjs().format('YYYY-MM-DD')
    }
  }
}
</script>

<style src="./Monthly.scss" lang="scss" scoped></style>
